
/* @property --angle {
  syntax: '<angle>';
  initial-value: 90deg;
  inherits: true;
}

@property --gradX {
  syntax: '<percentage>';
  initial-value: 50%;
  inherits: true;
}

@property --gradY {
  syntax: '<percentage>';
  initial-value: 0%;
  inherits: true;
}

body {
	font-family: Poppins;
}
@keyframes slide-in {
	from {
	  opacity: 0;
	}
  
	50% {
	  opacity: 0;
	}
  
	to {
	  opacity: 1;
	  translate: 0;
	}
  }

  @-webkit-keyframes animation_error_1 {
	from {
	  -webkit-transform: rotate(20deg);
	transform: rotate(20deg);
	}
	to {  -webkit-transform: rotate(25deg);
	transform: rotate(25deg);
	}
  }
  @-o-keyframes animation_error_1 {
	from {
	  -webkit-transform: rotate(20deg);
	transform: rotate(20deg);
	}
	to {  -webkit-transform: rotate(25deg);
	transform: rotate(25deg);
	}
  
  }
  @-moz-keyframes animation_error_1 {
	from {
	  -webkit-transform: rotate(20deg);
	transform: rotate(20deg);
	}
	to {  -webkit-transform: rotate(25deg);
	transform: rotate(25deg);
	}
  
  }
  @keyframes animation_error_1 {
	from {
	  -webkit-transform: rotate(20deg);
	transform: rotate(20deg);
	}
	to {  -webkit-transform: rotate(25deg);
	transform: rotate(25deg);
	}
  }
  
  
  
  
  @-webkit-keyframes animation_error_2 {
	from { -webkit-transform: rotate(-15deg); 
	transform: rotate(-15deg);
	}
	to { -webkit-transform: rotate(-20deg);
	transform: rotate(-20deg);
	}
  }
  @-o-keyframes animation_error_2 {
	from { -webkit-transform: rotate(-15deg); 
	transform: rotate(-15deg);
	}
	to { -webkit-transform: rotate(-20deg);
	transform: rotate(-20deg);
	}
  }
  
  @-moz-keyframes animation_error_2 {
	from { -webkit-transform: rotate(-15deg); 
	transform: rotate(-15deg);
	}
	to { -webkit-transform: rotate(-20deg);
	transform: rotate(-20deg);
	}
  }
  @keyframes animation_error_2 {
	from { -webkit-transform: rotate(-15deg); 
	transform: rotate(-15deg);
	}
	to { -webkit-transform: rotate(-20deg);
	transform: rotate(-20deg);
	}
  }
   */
  
  [slide-in-left] {
    opacity: 0;
    translate: -500px 0;
    animation: slide-in var(--ani-speed) ease-out forwards;
    animation-delay: calc(var(--order, 0ms) * var(--ani-speed));
  }
:root {
	--d: 2000ms;
	--angle: 50deg;
	--gradX: 100%;
	--gradY: 50%;
	--c1: rgb(200, 21, 235);
	--c2:  rgba(209, 32, 232, 0.612);

}
.cont_principal {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.cont_aura_1 {
    position: absolute;
    width: 300px;
    height: 120%;
    top: 25px;
    right: -340px;
    background-color: #8A65DF;
    box-shadow: 0px 0px 60px 20px rgba(137, 100, 222, 0.5);
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    z-index: -1;
    opacity: 70%;
    transform: rotate(20deg);
    right: -170px;
    animation-name: animation_error_1;
    animation-duration: 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.cont_aura_2 {
    position: absolute;
    width: 100%;
    height: 300px;
    right: -10%;
    bottom: -301px;
    background-color: #8B65E4;
    box-shadow: 0px 0px 60px 10px rgba(131, 95, 214, 0.5), 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    z-index: 5;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    z-index: -1;
    opacity: 70%;
    animation-name: animation_error_2;
    animation-duration: 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    transform: rotate(-20deg);
}