@import url("css/data-view.css");
@import url("css/nav-bar.css");
@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100..900;1,100..900&display=swap');

@import url("css/bg-animation.css");
@-webkit-keyframes loader7{
  0%{left: -12px; top: -12px;}
  25%{left:42px; top:-12px;}
  50%{left: 42px; top: 42px;}
  75%{left: -12px; top: 42px;}
  100%{left:-12px; top:-12px;}
}

@keyframes loader7{
  0%{left: -12px; top: -12px;}
  25%{left:70px; top:-12px;}
  50%{left: 70px; top: 70px;}
  75%{left: -12px; top: 70px;}
  100%{left:-12px; top:-12px;}
}

@-webkit-keyframes loader72{
  0%{width: 0px;}
  70%{width: 70px; opacity: 1;}
  90%{opacity: 0; width: 70px;}
  100%{opacity: 0;width: 0px;}
}

@keyframes loader72{
  0%{width: 0px;}
  70%{width: 70px; opacity: 1;}
  90%{opacity: 0; width: 70px;}
  100%{opacity: 0;width: 0px;}
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
table, th, td {
  border: 2px solid;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#style-scroll-bar::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
	background-color: none;
  opacity: 100;
	border-radius: 10px;
}

#style-scroll-bar::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

#style-scroll-bar::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-color:#FFF;
	background-image: -webkit-linear-gradient(top,
											  #f5e6f8 5%,
											  #df84fb 50%,
											  #ea7ffa 51%,
											  #ad0bf2 100%);
}

.dasboard-container
{
  border-radius: 30px;
  background-color:rgba(255, 255, 255, 0.729);
}

.no-scrollbar::-webkit-scrollbar {
  display: none;  
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.welcome-banner {
  padding: 18px 20px;
  color: white;
  background: #b107c7;
  border-radius: 20px;
}
.circle-progress-outer{
  height: 50px;
  width: 50px;
  border-radius: 10px;
  padding: 15px;
  border-radius: 14px;
  background: #FBF6FC;
  box-shadow:  10px -10px 21px #e9e5ea,
               -10px 10px 21px #ffffff;
}


.box{
	display: inline-block;
margin: auto;
	width: 100%;
	/*margin:0 -4px -5px -2px;*/
	transition: all .2s ease;
}
body{
    font-family: "Epilogue", sans-serif;
}








/* -------------- loader7 -------------- */

.loader7{
	position: relative;
	width: 70px;
	height: 40px;
	top: 40%;
	top: -webkit-calc(50% - 20px);
	top: calc(50% - 20px);
	left: 43%;
  top:50%;
	left: -webkit-calc(50% - 20px);
	left: calc(50% - 20px);
	background-color: rgba(242, 141, 233, 0.463);
}

.loader7:before{
	content: "";
	position: absolute;
	background-color: #d812cb;
	height: 10px;
	width: 10px;
	border-radius: 10px;
	-webkit-animation: loader7 2s ease-in-out infinite;
			animation: loader7 2s ease-in-out infinite;
}

.loader7:after{
	content: "";
	position: absolute;
	background-color: #d812cbad;
	top: 0px;
	left: 0px;
	height: 62px;
	width: 0px;
	z-index: 0;
	opacity: 1;
	-webkit-animation: loader72 10s ease-in-out infinite;
			animation: loader72 10s ease-in-out infinite;
}

