@keyframes firstShow {
    0%, 100% { transform: perspective(1000px) translate3d(0, 0, 0em); }
    50% { transform: perspective(1000px) translate3d(0, 0, 3em); }
  }
  
  @keyframes show {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
.alert-card-entry-animation {
    transition: transform 0.35s, box-shadow 0.35s, background 0.35s;
    transform: translate3d(0,0,0);
    opacity: 0;
    animation: 
      firstShow 0.5s ease-in-out,
      show 0.15s linear forwards;
    
    &:nth-child(1) { animation-delay: 0.50s; }
    &:nth-child(2) { animation-delay: 0.60s; }
    &:nth-child(3) { animation-delay: 0.70s; }
    &:nth-child(4) { animation-delay: 0.80s; }
    &:nth-child(5) { animation-delay: 0.90s; }
    &:nth-child(6) { animation-delay: 1.00s; }
    &:nth-child(7) { animation-delay: 1.10s; }
    &:nth-child(8) { animation-delay: 1.20s; }
    &:nth-child(9) { animation-delay: 1.30s; }
    &:nth-child(10) { animation-delay: 1.40s; }
    &:nth-child(11) { animation-delay: 1.50s; }
    &:nth-child(12) { animation-delay: 1.60s; }
    &:nth-child(13) { animation-delay: 1.70s; }
    &:nth-child(14) { animation-delay: 1.80s; }
    &:nth-child(15) { animation-delay: 1.90s; }
    &:nth-child(16) { animation-delay: 2.00s; }
    &:nth-child(17) { animation-delay: 2.10s; }
    &:nth-child(18) { animation-delay: 2.20s; }
    &:nth-child(19) { animation-delay: 2.30s; }
    &:nth-child(20) { animation-delay: 2.40s; }
  }



.filter-data-view
{
  border-radius: 10px;
  background: #F5E8FF;
  box-shadow:  -10px 5px 10px #ddd1e6,
               5px -5px 10px #ffffff;
  display: flex;
}