.header-box-selected{
	border-image: conic-gradient(from var(--angle), var(--c2), var(--c1) 0.8turn, var(--c1) 0.8turn, var(--c2) 0.8turn) 10;
	animation: borderRotate var(--d) linear infinite forwards;
    transform:  scale(1.2) translateY(5px);
    pointer-events: none;
	transition-delay: 150ms;
	transition-duration: 250ms;
}

.header-box-selected-menu{
	border-image: conic-gradient(from var(--angle), var(--c2), var(--c1) 0.8turn, var(--c1) 0.8turn, var(--c2) 0.8turn) 10;
	animation: borderRotate var(--d) linear infinite forwards;
    transform:  scale(1.2) translateY(5px);
	transition-delay: 150ms;
	transition-duration: 250ms;
}